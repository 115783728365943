import {
  CreateUserRequest,
  CreateUserResponse,
} from '@milkamo-inc/camper-interface-public/api';

import { queryClient, useApiClient } from 'api/apiClient';
import { QUERY_KEY_USER_SHOW_OWNED_CARS } from 'api/hooks/car/user/useShowOwnedCars';
import useUpdate from 'api/utils/useUpdate';
import toast from 'utils/toast';

export const MUTATION_KEY_CREATE_USER = 'createUser';

const useCreateUser: UseUpdate<CreateUserRequest, CreateUserResponse> = (
  options
) => {
  const api = useApiClient('createUser');

  const res = useUpdate(api, {
    mutationKey: [MUTATION_KEY_CREATE_USER],
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_USER_SHOW_OWNED_CARS],
      });
      queryClient.invalidateQueries();

      options?.onSuccess?.(data, variables, context);
    },
    onError: (error, variables, context) => {
      toast.error('ユーザー登録に失敗しました');

      options?.onError?.(error, variables, context);
    },
  });

  return res;
};

export default useCreateUser;
