import React, { createContext, useContext, useEffect, useState } from 'react';

import { CreateUserRequest } from '@milkamo-inc/camper-interface-public/api';
import { FirebaseError, initializeApp } from 'firebase/app';
import {
  AuthErrorCodes,
  AuthProvider,
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getAuth,
  linkWithPopup,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut as firebaseSignout,
  unlink,
  updatePassword,
} from 'firebase/auth';

import { queryClient } from 'api/apiClient';
import useCreateUser from 'api/hooks/user/useCreateUser';
import useShowCurrentUser from 'api/hooks/user/useShowCurrentUser';

initializeApp({
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
});

export enum IdentityProvider {
  Google = 0,
  Facebook = 1,
}

type UseAuth = {
  authenticated: boolean;
  email: string;
  name: string;
} & (
  | {
      ready: false;
    }
  | {
      ready: true;
      signUp: (
        email: string,
        password: string,
        request: CreateUserRequest
      ) => Promise<void>;
      signUpWithSocialProvider: (request: CreateUserRequest) => Promise<void>;
      signIn: (email: string, password: string) => Promise<void>;
      signInWithSocialProvider: (
        provider: IdentityProvider
      ) => Promise<boolean>;
      hasPassword: boolean;
      linkedIdentityProviders: IdentityProvider[];
      linkSocialProvider: (provider: IdentityProvider) => Promise<void>;
      unlinkSocialProvider: (provider: IdentityProvider) => Promise<void>;
      changePassword: (
        oldPassword: string,
        newPassword: string
      ) => Promise<void>;
      sendPasswordResetCode: (email: string) => Promise<void>;
      resetPassword: (code: string, password: string) => Promise<void>;
      signOut: () => Promise<void>;
    }
);

const authContext = createContext<UseAuth>({
  authenticated: false,
  email: '',
  name: '',
  ready: false,
});

export const ProvideAuth: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => useContext(authContext);

const useProvideAuth = (): UseAuth => {
  const createUser = useCreateUser();
  const showCurrentUser = useShowCurrentUser({}, { enabled: false }); // update で退会済みかチェックする用
  // ready: 一度でもログインチェックが走ったかどうか
  const [ready, setReady] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [hasPassword, setHasPassword] = useState(false);
  const [linkedIdentityProviders, setLinkedIdentityProviders] = useState<
    IdentityProvider[]
  >([]);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const update = async (): Promise<boolean> => {
    try {
      const auth = getAuth();
      await new Promise((resolve, reject) =>
        onAuthStateChanged(auth, resolve, reject)
      );
      queryClient.clear();
      const res = await showCurrentUser.refetch();
      setEmail(
        auth.currentUser?.email ||
          auth.currentUser?.providerData.find((data) => data.email)?.email ||
          ''
      );
      setHasPassword(
        auth.currentUser?.providerData.some(
          (data) => data.providerId === EmailAuthProvider.PROVIDER_ID
        ) ?? false
      );
      setLinkedIdentityProviders(
        auth.currentUser?.providerData
          .map((data) => {
            switch (data.providerId) {
              case GoogleAuthProvider.PROVIDER_ID:
                return IdentityProvider.Google;
              case FacebookAuthProvider.PROVIDER_ID:
                return IdentityProvider.Facebook;
              default:
                return;
            }
          })
          .filter(
            (provider): provider is IdentityProvider => provider !== undefined
          ) ?? []
      );
      setName(auth.currentUser?.displayName || '');
      // 退会済み または SSO での新規登録
      if (res.isError) {
        throw res.error;
      }
      setAuthenticated(true);
      return true;
    } catch {
      setAuthenticated(false);
      return false;
    } finally {
      setReady(true);
    }
  };

  useEffect(() => void update(), []);

  const signUp = async (
    email: string,
    password: string,
    request: CreateUserRequest
  ): Promise<void> => {
    const auth = getAuth();
    // バックエンドが何らかのエラーを返した場合、Firebase 側にユーザーが残ってしまうため、EMAIL_EXISTS を一旦無視してログインする
    // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
    let emailExistsError;
    try {
      await createUserWithEmailAndPassword(auth, email, password);
    } catch (e) {
      if (
        e instanceof FirebaseError &&
        e.code === AuthErrorCodes.EMAIL_EXISTS
      ) {
        emailExistsError = e;
      } else {
        throw e;
      }
    }
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (e) {
      if (emailExistsError) {
        throw emailExistsError;
      }
      throw e;
    }
    await createUser.mutateAsync(request);
    await update();
  };

  const signUpWithSocialProvider = async (
    request: CreateUserRequest
  ): Promise<void> => {
    await createUser.mutateAsync(request);
    await update();
  };

  const signIn = async (email: string, password: string): Promise<void> => {
    await signInWithEmailAndPassword(getAuth(), email, password);
    await update();
    const res = await showCurrentUser.refetch();
    // 退会済み
    if (res.isError) {
      throw res.error;
    }
  };

  const identityProviderToAuthProvider = (
    provider: IdentityProvider
  ): AuthProvider => {
    switch (provider) {
      case IdentityProvider.Google: {
        const googleAuthProvider = new GoogleAuthProvider();
        googleAuthProvider.addScope(
          'https://www.googleapis.com/auth/userinfo.email'
        );
        googleAuthProvider.addScope(
          'https://www.googleapis.com/auth/userinfo.profile'
        );
        return googleAuthProvider;
      }
      case IdentityProvider.Facebook: {
        const facebookAuthProvider = new FacebookAuthProvider();
        facebookAuthProvider.addScope('email');
        facebookAuthProvider.addScope('public_profile');
        return facebookAuthProvider;
      }
    }
  };

  const signInWithSocialProvider = async (
    provider: IdentityProvider
  ): Promise<boolean> => {
    await signInWithPopup(getAuth(), identityProviderToAuthProvider(provider));
    return await update();
  };

  const linkSocialProvider = async (
    provider: IdentityProvider
  ): Promise<void> => {
    const auth = getAuth();
    if (!auth.currentUser) {
      throw new Error('No current user');
    }
    await linkWithPopup(
      auth.currentUser,
      identityProviderToAuthProvider(provider)
    );
    await update();
  };

  const unlinkSocialProvider = async (
    provider: IdentityProvider
  ): Promise<void> => {
    const auth = getAuth();
    if (!auth.currentUser) {
      throw new Error('No current user');
    }
    await unlink(
      auth.currentUser,
      identityProviderToAuthProvider(provider).providerId
    );
    await update();
  };

  const changePassword = async (
    oldPassword: string,
    newPassword: string
  ): Promise<void> => {
    const auth = getAuth();
    if (!auth.currentUser) {
      throw new Error('No current user');
    }
    await reauthenticateWithCredential(
      auth.currentUser,
      EmailAuthProvider.credential(email, oldPassword)
    );
    await updatePassword(auth.currentUser, newPassword);
  };

  const sendPasswordResetCode = async (email: string): Promise<void> => {
    await sendPasswordResetEmail(getAuth(), email);
    setEmail(email);
  };

  const resetPassword = async (
    code: string,
    password: string
  ): Promise<void> => {
    await confirmPasswordReset(getAuth(), code, password);
  };

  const signOut = async (): Promise<void> => {
    await firebaseSignout(getAuth());
    queryClient.clear();
    setAuthenticated(false);
    setEmail('');
    setName('');
  };

  return {
    authenticated,
    email,
    name,
    ready,
    signUp,
    signUpWithSocialProvider,
    signInWithSocialProvider,
    signIn,
    hasPassword,
    linkedIdentityProviders,
    linkSocialProvider,
    unlinkSocialProvider,
    changePassword,
    sendPasswordResetCode,
    resetPassword,
    signOut,
  };
};
