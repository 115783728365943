import { ShowCurrentUserResponse } from '@milkamo-inc/camper-interface-public/api';

import { useApiClient } from 'api/apiClient';
import useFetch from 'api/utils/useFetch';

export const QUERY_KEY_USER_SHOW_CURRENT_USER = 'ShowCurrentUser';

const useShowCurrentUser: UseFetch<object, ShowCurrentUserResponse> = (
  payload,
  options
) => {
  const api = useApiClient('showCurrentUser');

  const res = useFetch([QUERY_KEY_USER_SHOW_CURRENT_USER], () => api(payload), {
    ...options,
  });

  return res;
};

export default useShowCurrentUser;
