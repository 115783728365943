import React, { createContext, useContext, useCallback } from 'react';

import * as campers from '@milkamo-inc/camper-interface-public/api';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import toast from 'utils/toast';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5, // 5 minutes
    },
  },
  queryCache: new QueryCache({
    onError: (_, query) => {
      if (typeof query.meta?.errorMessage === 'string') {
        toast.error(query.meta.errorMessage);
      }
    },
  }),
  mutationCache: new MutationCache(),
});

const campersApiClient = {
  ...campers.CarUserServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.InquiryUserServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.ReserveUserServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.SpotUserServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.CarMasterUserServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.UserServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.SpotMasterUserServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.ReviewUserServiceApiFactory(undefined, __BACKEND_BASE_URL__),
};

type ApiClient = typeof campersApiClient;

export const ApiClientContext = createContext<ApiClient | undefined>(undefined);

export const ApiClientProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <ApiClientContext.Provider value={campersApiClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </ApiClientContext.Provider>
  );
};

export const useApiClient = <K extends keyof ApiClient>(
  key: K,
  config?: AxiosRequestConfig
) => {
  const apiClient = useContext<ApiClient | undefined>(ApiClientContext);
  if (!apiClient) {
    throw new Error('API client is not set');
  }

  const api = apiClient[key];
  const call = useCallback(
    async (
      payload: Partial<Parameters<ApiClient[K]>[0]>
    ): Promise<Awaited<ReturnType<ApiClient[K]>>['data']> => {
      const auth = getAuth();
      await new Promise((resolve, reject) =>
        onAuthStateChanged(auth, resolve, reject)
      );
      const token = await auth.currentUser?.getIdToken();
      if (!token) {
        throw new Error('No token provided');
      }

      return await api(payload as never, {
        ...config,
        headers: {
          ...config?.headers,
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => res.data);
    },
    [api, config]
  );

  return call;
};

const camperPublicApiClient = {
  ...campers.ArticlePublicServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.CarPublicServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.ContactPublicServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.FaqPublicServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.NewsPublicServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.OwnerInterviewPublicServiceApiFactory(
    undefined,
    __BACKEND_BASE_URL__
  ),
  ...campers.CarMasterPublicServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.SpotMasterPublicServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.ReservePublicServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.ReviewPublicServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.SpotPublicServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.TermsPublicServiceApiFactory(undefined, __BACKEND_BASE_URL__),
};

type PublicApiClient = typeof camperPublicApiClient;

export const PublicApiClientContext = createContext<
  PublicApiClient | undefined
>(undefined);

export const PublicApiClientProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return (
    <PublicApiClientContext.Provider value={camperPublicApiClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </PublicApiClientContext.Provider>
  );
};

export const usePublicApiClient = <K extends keyof PublicApiClient>(
  key: K,
  config?: AxiosRequestConfig
) => {
  const publicApiClient = useContext<PublicApiClient | undefined>(
    PublicApiClientContext
  );
  if (!publicApiClient) {
    throw new Error('API client is not set');
  }

  const api = publicApiClient[key];
  const call = useCallback(
    async (
      payload: Parameters<PublicApiClient[K]>[0]
    ): Promise<Awaited<ReturnType<PublicApiClient[K]>>['data']> => {
      return await api(payload as never, {
        config,
      }).then((res) => res.data);
    },
    [api, config]
  );

  return call;
};
